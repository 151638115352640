




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'KText',
    props: {
        text: {
            type: String,
            required: true
        }
    },
    setup() {
        //

        return {
            //
        }
    }
})
