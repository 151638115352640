





















































import { defineComponent, onMounted, ref } from "@vue/composition-api"
import KDialog from "../dialogs/KDialog.vue";
import { IS_INSTALLED, IS_ANDROID, IS_IOS } from "@/@core/helpers/is-installed"
import KCard from "../other/KCard.vue";
import { isMobile } from "@/@core/helpers/useBreakpoint";
import KText from "../typography/KText.vue";
import { useUiStateStore } from "@/@core/services/uiStateStore";

export default defineComponent({
  components: { KDialog, KCard, KText },
  name: "KInstallButton",
  setup() {
    const uiStore = useUiStateStore()
    const showDialog = ref(false)

    const installApp = async () => {
        if (uiStore.deferredPrompt !== null) {
            uiStore.deferredPrompt.prompt()
            const { outcome } = await uiStore.deferredPrompt.userChoice
            if (outcome === 'accepted') {
                // uiStore.deferredPrompt = null
                showDialog.value = false
                uiStore.createSnackbar({
                    message: 'Aplikasi berhasil diinstall~',
                    arrayMessage: [
                        'Silahkan buka aplikasi layar awal hp anda',
                        'Atau di daftar aplikasi hp anda',
                    ],
                    timeout: 20000
                })
            }
            if (outcome === 'dismissed') {
                // uiStore.deferredPrompt = null
                showDialog.value = false
            }

            uiStore.resetDefferedPrompt()
        }
    }

    return {
      showDialog,
      IS_INSTALLED,
      installApp,
      isMobile,
      IS_ANDROID,
      IS_IOS,
    }
  }
})
