const UA = navigator.userAgent;

const IOS = UA.match(/iPhone|iPad|iPod/);
const ANDROID = UA.match(/Android/);

export const PLATFORM = IOS ? 'ios' : ANDROID ? 'android' : 'unknown';

const standalone = window.matchMedia('(display-mode: standalone)').matches;

export const IS_INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)));
export const IS_ANDROID = ANDROID
export const IS_IOS = IOS
