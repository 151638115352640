























































import { computed, defineComponent, Ref} from '@vue/composition-api'

export default defineComponent({
    name: 'KDialogDesktop',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        useActions: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Title'
        },
        text: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: 'Batal'
        },
        submitText: {
            type: String,
            default: 'Simpan'
        },
        submitColor: {
            type: String,
            default: 'primary'
        },
        submitDisabled: {
            type: Boolean,
            default: false
        },
    },
    emits: ['cancel', 'submit'],
    setup(setup, { emit }) {
        const showDialog: Ref<boolean> = computed({
            get: () => setup.value,
            set: (newValue: boolean) => emit('input', newValue)
        })

        const onCancel = () => {
            showDialog.value = false
            emit('cancel')

            return
        }

        const onSubmit = () => {
            emit('submit')
            
            return
        }

        return {
            showDialog,
            onCancel,
            onSubmit
        }
    }
})
